import _config from '@exp/exp-utils/helper/config';

_config.merge({
  'tcc.listenerDisabled': false,
  'tcc.manualPagePerf': false,
  'tcc.loadTTI': true,
  'tcc.eventDelayMs': 75,
  'tcc.perfDelayMs': 250,
  'tcc.consentDelayMs': 500,
  'tcc.gaAccountDev': 'UA-52262008-15',
  'tcc.gaAccountTest': 'UA-37178807-21',
  'tcc.gaAccountProd': 'UA-115508484-1',
  'tcc.buildEnv': 'production',
  'tcc.buildName': '@tcc/tcc',
  'tcc.buildVersion': '3.11.0',
  'tcc.buildDate': '2021-08-23T20:36:35.141Z',
  'tcc.splitioUrl': 'https://img6.wsimg.com/wrhs/7f77e4ddc5077a5c6c50ee67de56f864/splitio.min.js',
  'tcc.splitioApiKey': '26b1lpi1u5ifu0ug7hj9t68j43qi0b1sulf6',
  'tcc.ttiUrl': 'https://img6.wsimg.com/wrhs/ce554d2333f3801abafb32da18213ff7/tti.min.js'
});

export default _config;
